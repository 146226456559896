<template>
  <!-- 时间 -->
  <div>
    <span v-if="title"> {{ title }} </span>
    <el-date-picker
      v-model="Time"
      class="lelect-time m-l-8"
      :type="timeType"
      :value-format="format"
      range-separator="-"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
    >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    timeType: {
      type: String,
      default: "daterange",
    },
    format: {
      type: String,
      default: "yyyy-MM-dd HH:mm:ss",
    },
  },
  data() {
    return {
      Time: [],
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep
  .el-date-editor.el-range-editor.el-input__inner.lelect-time.el-date-editor--daterange {
  height: 36px;
  width: 280px;
  border-radius: 4px;
  .el-range-input {
    font-size: 13px;
    width: 100px;
  }
  .el-range-input:nth-child(4) {
    margin-left: 10px;
  }
  .el-input__icon.el-range__icon.el-icon-date {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-input__icon.el-range__close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-range-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}
</style>